import React, { useEffect, useState, createContext } from 'react';
import Axios from 'axios';

export const ClassroomList = createContext();

export function ClassroomListProvider({ children }) {
  const [crList, setCrList] = useState([]);
  const [planData, setPlanData] = useState(null);
  
  useEffect(() => {
    checkJWT();
    //eslint-disable-next-line
  }, [localStorage.getItem('JWT')]);

  const checkJWT = () => {
    getClassroomData();
  };

  const getClassroomData = () => {
    if (
      (localStorage.getItem('JWT') && localStorage.getItem('JWT') !== '') ||
      localStorage.getItem('JWT') !== null
    ) {
      Axios.get('/api/classroom/search?sortBy=-createdAt', {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
        .then((response) => {
          setCrList(response.data.filter((i) => i.classroom !== undefined));
        })
        .catch((err) => {
          console.error('error del classroomListContext: ', err);
          setTimeout(checkJWT, 1000);
        });
    } else {
      setTimeout(checkJWT, 1000);
    }
  };

  function updateClassRoomList() {
    getClassroomData();
  }

  const setPlanContext = (data) => {
    setPlanData(data);
  };

  const getPlanContext = () => {
    return planData;
  };

  return (
    <ClassroomList.Provider
      value={{ crList, updateClassRoomList, setPlanContext, getPlanContext }}
    >
      {children}
    </ClassroomList.Provider>
  );
}
