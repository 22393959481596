import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import clientAxios from '../../../../clients/clientAxios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ClassroomList } from '../../../../Context/ClassroomListContext';

export const ShowPlanById = ({ id, student, indexCommission }) => {
  const [show, onHide] = useState(false);
  const [planData, setPlanData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonFlag, setButtonFlag] = useState(false);

  const { setPlanContext } = useContext(ClassroomList);

  const getPlanById = async (id) => {
    try {
      const idWithoutQuotes = id.replace(/"/g, '');
      const { data } = await clientAxios(`/planes/${idWithoutQuotes}`);
      setPlanData(data);
      setPlanContext(data);
    } catch (error) {
      notify.show('Ha ocurrido un problema al cargar el plan', 'error');
      setIsLoading(false);
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getPlanById(id);
  }, []);

  return (
    <>
      <Modal show={show} onHide={onHide}>
        {isLoading ? (
          <>
            <Modal.Header>
              <Modal.Title>
                Detalle del Plan <strong>{planData?.name}</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <p>
                    <strong>Descripción:</strong>
                  </p>
                  <p>{planData?.description}</p>
                  <p>
                    <strong>Precio total:</strong>
                  </p>
                  <p>${planData?.totalAmount}</p>
                  <p>
                    <strong>Fecha de creación:</strong>
                  </p>
                  <p>
                    {moment(planData?.createdAt).format('DD/MM/YYYY hh:mm')}
                  </p>
                </Col>
                <Col md={6} className='d-flex justify-content-center'>
                  <ol>
                    {planData &&
                      planData?.priceSchema.map((cuota, index) => {
                        return index + 1 !=
                          student?.monthlyInstallments[indexCommission] ? (
                          <li key={index}>${cuota}</li>
                        ) : (
                          <li
                            key={index}
                            className='text-danger font-weight-bold'
                          >
                            ${cuota} *
                          </li>
                        );
                      })}
                  </ol>
                </Col>
              </Row>
            </Modal.Body>
          </>
        ) : (
          <Modal.Body>
            <h2>No se pudo cargar el Plan</h2>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Col className='d-flex justify-content-between align-items-center'>
            <p className='text-danger mb-0'>*Ultima cuota configurada</p>
            <Button variant='primary' onClick={() => onHide(!show)}>
              Aceptar
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
      <FontAwesomeIcon
        icon={faCircleInfo}
        size='xl'
        onClick={() => onHide(!show)}
        style={{ cursor: 'pointer' }}
      />
    </>
  );
};
