import React, { Component } from 'react';
import {
  Form,
  CardGroup,
  Button,
  Col,
  Row,
  Container,
  Modal,
} from 'react-bootstrap';
import { ClassroomList } from '../../Context/ClassroomListContext';
import LoadingScreen from '../../Layout/LoadingScreen';
import CourseCard from './subcomponents/CourseCard';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './studentCreateStyles.css';
import axios from 'axios';

class StudentCreate extends Component {
  static contextType = ClassroomList;
  constructor(props) {
    super(props);
    this.state = {
      // Loading State
      hasToReload: false,
      isLoading: true,
      ButtonLoading: false,

      // Modal State
      showModal: false,

      // Year validation
      maxYear: parseInt(new Date().getFullYear()),

      // Form States
      name: '',
      surname: '',
      dni: '',
      email: '',
      currentEmail: '',
      address: '',
      phone: '',
      phoneAlt: '',
      location: '',
      birthDate: '',

      englishClassroom: '',
      classroomYear: new Date().getFullYear(),
      contactOrigin: '',
      informativeTalkDate: '',
      currentJob: '',
      itKnowleadge: 'low',
      englishKnowleadge: 'low',
      codeKnowleadge: 'low',
      availableTimeFrom: '',
      availableTimeTo: '',
      preferredSite: 'S.M.T.',
      availableSat: false,
      additionalNotes: '',
      downReason: ['', '', ''],
      otherDownReason: ['', '', ''],
      moduleDownReason: ['', '', ''],
      commissionChangedReason: ['', '', ''],
      otherCommissionChangedReason: ['', '', ''],
      moduleCommissionChangedReason: ['', '', ''],
      ownNotebook: false,
      notebookModel: '',

      // Form Validation State

      validated: false,
      redirect: false,

      redirectToStudent: false,
      backToStudent: false,
      classroomList: [],
      eClassroomList: [],

      scholarship: [],
      course: [],
      classroom: ['', '', ''],
      monthlyInstallments: [],
      temporalInstallmentQuantity: '',
      price: [],
      isActive: ['up', '', ''],

      courseQuantity: 1,
      country: 'Argentina',
      region: 'Tucumán',
      changedInCommission: ['', '', ''],
      currency: ['ARS', '', ''],
      plan: [],
    };
  }

  handleCardClose = (e) => {
    let { courseQuantity } = this.state;
    if (courseQuantity === 1)
      notify.show('Tiene que existir al menos una comisión', 'error');
    else {
      let {
        course,
        classroom,
        monthlyInstallments,
        price,
        isActive,
        scholarship,
      } = this.state;
      courseQuantity--;
      course.splice(e, e === 0 ? 1 : e);
      classroom.splice(e, e === 0 ? 1 : e);
      monthlyInstallments.splice(e, e === 0 ? 1 : e);
      price.splice(e, e === 0 ? 1 : e);
      isActive.splice(e, e === 0 ? 1 : e);
      scholarship.splice(e, e === 0 ? 1 : e);

      course[courseQuantity] = '';
      classroom[courseQuantity] = '';
      monthlyInstallments[courseQuantity] = '';
      price[courseQuantity] = '';
      isActive[courseQuantity] = '';
      scholarship[courseQuantity] = false;

      this.setState({
        courseQuantity: courseQuantity,
        course,
        classroom,
        monthlyInstallments,
        price,
        isActive,
        scholarship,
      });
    }
  };

  handleInputQuantity = (event) => {
    let { courseQuantity } = this.state;

    if (event === 'add') {
      if (courseQuantity === 5)
        notify.show('No se pueden agregar más comisiones', 'error');
      else {
        this.setState((i) => ({ courseQuantity: ++i.courseQuantity }));
      }
    }
  };

  handleChange = ({ target }) => {
    let { name, value, type, checked } = target;

    this.setState({ [name]: type === 'checkbox' ? checked : value });
  };

  handleCardSave = (data, id) => {
    const planes = this.state.plan || [];
    let arrayCourse =
        typeof this.state.course === 'object' ? this.state.course : [],
      arrayClassroom =
        typeof this.state.classroom === 'object' ? this.state.classroom : [],
      arrayMonthlyInstallments =
        typeof this.state.monthlyInstallments === 'object'
          ? this.state.monthlyInstallments
          : [],
      arrayPrice = typeof this.state.price === 'object' ? this.state.price : [],
      arrayIsActive =
        typeof this.state.isActive === 'object' ? this.state.isActive : [],
      arrayScholarship =
        typeof this.state.scholarship === 'object'
          ? this.state.scholarship
          : [],
      arrayDownReason =
        typeof this.state.downReason === 'object' ? this.state.downReason : [],
      arrayOtherDownReason =
        typeof this.state.otherDownReason === 'object'
          ? this.state.otherDownReason
          : [],
      arrayModuleDownReason =
        typeof this.state.moduleDownReason === 'object'
          ? this.state.moduleDownReason
          : [],
      arrayCommissionChangedReason =
        typeof this.state.commissionChangedReason === 'object'
          ? this.state.commissionChangedReason
          : [],
      arrayOtherCommissionChangedReason =
        typeof this.state.otherCommissionChangedReason === 'object'
          ? this.state.otherCommissionChangedReason
          : [],
      arrayModuleCommissionChangedReason =
        typeof this.state.moduleCommissionChangedReason === 'object'
          ? this.state.moduleCommissionChangedReason
          : [],
      arrayChangedInCommission =
        typeof this.state.changedInCommission === 'object'
          ? this.state.changedInCommission
          : [],
      arrayCurrency =
        typeof this.state.currency === 'object' ? this.state.currency : [],
      tempState = { ...data };

    if (!data.course) {
      delete tempState.course;
      tempState.course = null;
    }

    arrayCourse[id] = tempState.course;
    arrayClassroom[id] = tempState.classroom;
    arrayMonthlyInstallments[id] = tempState.monthlyInstallments;
    arrayPrice[id] = tempState.price;
    arrayIsActive[id] = tempState.isActive;
    arrayScholarship[id] = tempState.scholarship;
    arrayDownReason[id] = tempState.downReason;
    arrayOtherDownReason[id] = tempState.otherDownReason;
    arrayModuleDownReason[id] = tempState.moduleDownReason;
    arrayCommissionChangedReason[id] = tempState.commissionChangedReason;
    arrayOtherCommissionChangedReason[id] =
      tempState.otherCommissionChangedReason;
    arrayModuleCommissionChangedReason[id] =
      tempState.moduleCommissionChangedReason;
    arrayChangedInCommission[id] = tempState.changedInCommission;
    arrayCurrency[id] = tempState.currency;

    let classRoomSelected = null;
    if (this.context?.crList?.length > 0) {
      classRoomSelected = this.context?.crList.find(
        ({ classroom }) => classroom === tempState.classroom
      );
    }

    if (classRoomSelected?._id) {
      planes[id] = {
        planId: data?.plan || data.planes[0]?._id,
        classroom: classRoomSelected.classroom,
        classroomId: classRoomSelected?._id,
      };
    }

    this.setState({
      course: arrayCourse,
      classroom: arrayClassroom,
      monthlyInstallments: arrayMonthlyInstallments,
      price: arrayPrice,
      isActive: arrayIsActive,
      scholarship: arrayScholarship,
      downReason: arrayDownReason,
      otherDownReason: arrayOtherDownReason,
      moduleDownReason: arrayModuleDownReason,
      commissionChangedReason: arrayCommissionChangedReason,
      otherCommissionChangedReason: arrayOtherCommissionChangedReason,
      moduleCommissionChangedReason: arrayModuleCommissionChangedReason,
      changedInCommission: arrayChangedInCommission,
      currency: arrayCurrency,
      temporalInstallmentQuantity: tempState.monthlyInstallments,
      plan: planes,
      planSelected: {
        planId: data?.plan,
        classroom: tempState.classroom,
        classroomId: classRoomSelected?._id,
      },
    });
  };

  returnHome = () => {
    this.props.history.push('/students/viewAll');
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        cancelUrl: `/students/details/${this.props.match.params.id}`,
      });
      axios
        .get(`/api/students/${this.props.match.params.id}`, {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        })
        .then((data) => {
          var {
            isActive,
            name,
            surname,
            dni,
            email,
            currentEmail,
            address,
            phone,
            phoneAlt,
            location,
            birthDate,
            classroom,
            englishClassroom,
            classroomYear,
            contactOrigin,
            informativeTalkDate,
            currentJob,
            itKnowleadge,
            englishKnowleadge,
            codeKnowleadge,
            availableTimeFrom,
            availableTimeTo,
            preferredSite,
            availableSat,
            additionalNotes,
            course,
            ownNotebook,
            notebookModel,
            price,
            monthlyInstallments,
            scholarship,
            downReason,
            otherDownReason,
            moduleDownReason,
            commissionChangedReason,
            otherCommissionChangedReason,
            moduleCommissionChangedReason,
            country,
            region,
            currency,
            plan,
          } = data.data.response;

          let courseQuantity = data.data.response.courseQuantity || 1;

          if (
            typeof course !== 'object' &&
            typeof classroom !== 'object' &&
            typeof price !== 'object' &&
            typeof monthlyInstallments !== 'object' &&
            typeof isActive !== 'object' &&
            typeof scholarship !== 'object'
          ) {
            var classroomArray = [],
              priceArray = [],
              monthlyInstallmentsArray = [],
              courseArray = [],
              isActiveArray = [],
              scholarshipArray = [];

            courseArray.push(course);
            classroomArray.push(classroom);
            priceArray.push(price);
            monthlyInstallmentsArray.push(monthlyInstallments);
            isActiveArray.push(isActive);
            scholarshipArray.push(scholarship);
          }

          this.setState({
            isLoading: false,
            name,
            surname,
            dni,
            email,
            address,
            currentEmail: email,
            phone,
            phoneAlt,
            location,
            birthDate,
            englishClassroom,
            classroomYear,
            contactOrigin,
            informativeTalkDate,
            currentJob,
            itKnowleadge,
            englishKnowleadge,
            codeKnowleadge,
            availableTimeFrom,
            availableTimeTo,
            preferredSite,
            availableSat,
            additionalNotes,
            country,
            region,
            ownNotebook,
            notebookModel,
            course: courseArray || course,
            classroom: classroomArray || classroom,
            price: priceArray || price !== null ? price : 0,
            monthlyInstallments:
              monthlyInstallmentsArray || monthlyInstallments,
            isActive: isActiveArray || isActive,
            scholarship: scholarshipArray || scholarship,
            downReason,
            otherDownReason:
              otherDownReason === null ? ['', '', ''] : otherDownReason,
            moduleDownReason:
              moduleDownReason === null ? ['', '', ''] : moduleDownReason,
            commissionChangedReason,
            otherCommissionChangedReason:
              otherCommissionChangedReason === null
                ? ['', '', '']
                : otherCommissionChangedReason,
            moduleCommissionChangedReason:
              moduleCommissionChangedReason === null
                ? ['', '', '']
                : moduleCommissionChangedReason,
            courseQuantity,
            currency,
            plan,
          });
        })
        .catch(() => {
          notify.show(
            'Ha ocurrido un error obteniendo los datos, reintente.',
            'error'
          );
          this.props.history.push('/students/viewAll');
        });
    } else {
      this.setState({ cancelUrl: `/students/viewAll` });
      this.setState({ isLoading: false });
    }
  }

  onSubmitToCompleteSave = (e) => {
    if (e) e.preventDefault();
    var { email, currentEmail } = this.state;
    if (email !== currentEmail && currentEmail !== '') {
      this.setState({ showModal: true });
    } else {
      this.onSubmit(e);
    }
  };

  onSubmit = (e) => {
    if (e) e.preventDefault();
    this.setState({ showModal: false });
    this.setState({ ButtonLoading: true });
    var {
      name,
      surname,
      dni,
      email,
      currentEmail,
      address,
      phone,
      phoneAlt,
      location,
      birthDate,
      classroom,
      englishClassroom,
      classroomYear,
      contactOrigin,
      courseQuantity,
      informativeTalkDate,
      currentJob,
      itKnowleadge,
      englishKnowleadge,
      codeKnowleadge,
      availableTimeFrom,
      availableTimeTo,
      preferredSite,
      availableSat,
      additionalNotes,
      course,
      price,
      monthlyInstallments,
      scholarship,
      downReason,
      otherDownReason,
      moduleDownReason,
      commissionChangedReason,
      otherCommissionChangedReason,
      moduleCommissionChangedReason,
      isActive,
      region,
      country,
      ownNotebook,
      notebookModel,
      redirectToStudent,
      backToStudent,
      changedInCommission,
      currency,
      temporalInstallmentQuantity,
      plan,
    } = this.state;

    if (downReason !== 'down') {
      this.setState({ otherDownReason: null });
    }
    if (email && this.props.match.params.id) {
      const studentEmail = email;
      const studentOldEmail = currentEmail;
      axios
        .patch(
          `/api/bills`,
          { studentEmail, studentOldEmail },
          { headers: { 'auth-token': localStorage.getItem('JWT') || ' ' } }
        )
        .catch((err) => {
          this.setState({ ButtonLoading: false });
          notify.show(`${err.message}`, `error`);
        });
    }

    if (this.props.match.params.id) {
      axios
        .put(
          `/api/students/${this.props.match.params.id}`,
          {
            name,
            surname,
            dni,
            email: this.state.email.toLowerCase(),
            address,
            phone,
            phoneAlt,
            location,
            birthDate,
            englishClassroom,
            classroomYear,
            contactOrigin,
            courseQuantity,
            informativeTalkDate,
            currentJob,
            itKnowleadge,
            englishKnowleadge,
            codeKnowleadge,
            availableTimeFrom,
            availableTimeTo,
            preferredSite,
            ownNotebook,
            notebookModel,
            availableSat,
            additionalNotes,
            course: course,
            price: price,
            monthlyInstallments: monthlyInstallments,
            isActive: isActive,
            classroom: classroom,
            scholarship,
            downReason,
            otherDownReason,
            moduleDownReason,
            commissionChangedReason,
            otherCommissionChangedReason,
            moduleCommissionChangedReason,
            validated: true,
            region,
            country,
            changedInCommission,
            currency,
            temporalInstallmentQuantity,
            plan,
          },
          { headers: { 'auth-token': localStorage.getItem('JWT') || ' ' } }
        )
        .then((res) => {
          let { success, message } = res.data;
          if (success) {
            notify.show(`${message}`, `success`);
            this.setState({ ButtonLoading: false });
            if (backToStudent) {
              this.props.history.push(
                '/students/details/' + this.props.match.params.id
              );
            } else {
              this.returnHome();
            }
          } else {
            notify.show(`${message}`, `warning`);
            this.setState({ ButtonLoading: false });
          }
        })
        .catch((err) => {
          this.setState({ ButtonLoading: false });
          notify.show(`${err.message}`, `error`);
        });
    } else {
      axios
        .post(
          '/api/students',
          {
            name: (name[0].toUpperCase() + name.slice(1)).trim(),
            surname: (surname[0].toUpperCase() + surname.slice(1)).trim(),
            dni,
            email: this.state.email.toLowerCase(),
            courseQuantity,
            address,
            phone,
            phoneAlt,
            location,
            birthDate,
            englishClassroom,
            classroomYear,
            contactOrigin,
            informativeTalkDate,
            currentJob,
            itKnowleadge,
            englishKnowleadge,
            codeKnowleadge,
            availableTimeFrom,
            availableTimeTo,
            preferredSite,
            ownNotebook,
            notebookModel,
            availableSat,
            additionalNotes,
            course: course.filter(Boolean),
            price: price.filter(Boolean),
            monthlyInstallments: monthlyInstallments.filter(Boolean),
            isActive: isActive.filter(Boolean),
            classroom: classroom.filter(Boolean),
            scholarship,
            downReason,
            otherDownReason,
            moduleDownReason,
            commissionChangedReason,
            otherCommissionChangedReason,
            moduleCommissionChangedReason,
            region,
            country,
            currency,
            temporalInstallmentQuantity,
            plan: this.state.plan,
          },
          { headers: { 'auth-token': localStorage.getItem('JWT') || ' ' } }
        )
        .then((res) => {
          let { success, message, id } = res.data;
          if (success) {
            notify.show(`${message}`, `success`);
            this.setState({ ButtonLoading: false });
            if (this.state.hasToReload) {
              window.scrollTo(0, 0);
              window.location.reload(true);
            } else {
              if (redirectToStudent) {
                this.props.history.push('/students/details/' + id);
              } else {
                this.returnHome();
              }
            }
          } else {
            notify.show(`${message}`, `warning`);
            this.setState({ ButtonLoading: false });
            return false;
          }
        })
        .catch((err) => {
          notify.show(`Ocurrio un error: ${err.message}`, 'error');
          this.setState({ ButtonLoading: false });
          this.returnHome();
        });
    }

    return false;
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const crList = this.context.crList;
    let inputQtty = [];
    for (let i = 0; i < this.state.courseQuantity; i++) {
      inputQtty.push(i);
    }
    var Cards = inputQtty.map((x) => {
      return (
        <CourseCard
          course={this.state.course}
          classroom={this.state.classroom}
          monthlyInstallments={this.state.monthlyInstallments}
          price={this.state.price}
          isActive={this.state.isActive}
          downReason={this.state.downReason}
          otherDownReason={this.state.otherDownReason}
          moduleDownReason={this.state.moduleDownReason}
          commissionChangedReason={this.state.commissionChangedReason}
          otherCommissionChangedReason={this.state.otherCommissionChangedReason}
          moduleCommissionChangedReason={
            this.state.moduleCommissionChangedReason
          }
          scholarship={this.state.scholarship}
          classroomList={crList.filter((i) => i.isActive === 'active')}
          key={x}
          cardId={x}
          handleSave={(data) => this.handleCardSave(data, x)}
          handleClose={() => this.handleCardClose(x)}
          currency={this.state.currency}
          plan={this.state.plan}
        />
      );
    });

    if (this.state.isLoading) {
      return <LoadingScreen />;
    }

    return (
      <Container>
        <Form onSubmit={this.onSubmitToCompleteSave} autoComplete='off'>
          <Row>
            <Col>
              <Form.Group controlId='name'>
                <Form.Label>
                  Nombre<strong className='text-danger'>*</strong>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Nombre'
                  value={this.state.name}
                  onChange={this.handleChange}
                  name='name'
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='surname'>
                <Form.Label>
                  Apellido<strong className='text-danger'>*</strong>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Apellido'
                  value={this.state.surname}
                  name='surname'
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='dni'>
                <Form.Label>D.N.I.<strong className='text-danger'>*</strong></Form.Label>
                <Form.Control
                  type='number'
                  placeholder='D.N.I.'
                  value={this.state.dni}
                  name='dni'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId='email'>
                <Form.Label>
                  E-Mail<strong className='text-danger'>*</strong>
                </Form.Label>
                <Form.Control
                  type='email'
                  placeholder='E-Mail'
                  value={this.state.email}
                  name='email'
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='address'>
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Direccion'
                  value={this.state.address}
                  name='address'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='phone'>
                <Form.Label>
                  Teléfono<strong className='text-danger'>*</strong>
                </Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Teléfono'
                  value={this.state.phone}
                  name='phone'
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId='phoneAlt'>
                <Form.Label>Teléfono Alternativo</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Teléfono Alternativo'
                  value={this.state.phoneAlt}
                  name='phoneAlt'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='location'>
                <Form.Label>Localidad:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Localidad'
                  value={this.state.location}
                  name='location'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='birthDate'>
                <Form.Label>Fecha de nacimiento:</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Fecha de nacimiento'
                  value={this.state.birthDate}
                  name='birthDate'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group name='country' label='País'>
                <Form.Label>País:</Form.Label>
                <CountryDropdown
                  className='customSelect'
                  name='country'
                  defaultOptionLabel='Seleccionar país'
                  showDefaultOption='Argentina'
                  priorityOptions={['AR']}
                  value={this.state.country}
                  onChange={(country) => {
                    this.setState({ country });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group name='region' label='Provincia'>
                <Form.Label>Provincia:</Form.Label>
                <RegionDropdown
                  className='customSelect'
                  country={this.state.country}
                  defaultOptionLabel='Seleccionar region'
                  value={this.state.region}
                  onChange={(region) => this.setState({ region })}
                />
              </Form.Group>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col>
              <Form.Group controlId='classroomYear'>
                <Form.Label>
                  Año<strong className='text-danger'>*</strong>:
                </Form.Label>
                <Form.Control
                  type='number'
                  value={this.state.classroomYear}
                  name='classroomYear'
                  onChange={this.handleChange}
                  min='2019'
                  max={this.state.maxYear}
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='Notas adicionales'>
                <Form.Label>Notas adicionales:</Form.Label>
                <Form.Control
                  as='textarea'
                  type='text'
                  placeholder='Notas adicionales'
                  value={this.state.additionalNotes}
                  name='additionalNotes'
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <hr />

          <CardGroup>{Cards}</CardGroup>

          <Row>
            <Col>
              <Button
                variant='info'
                className='m-0 mr-3 w-100'
                onClick={() => {
                  this.handleInputQuantity('add');
                }}
              >
                Agregar curso
              </Button>
            </Col>
          </Row>

          <hr className='my-4' />

          <Row>
            <Form.Group as={Col} controlId='ownNotebook'>
              <Form.Label>Posee Notebook en comodato</Form.Label>
              <Form.Control
                type='checkbox'
                value={this.state.ownNotebook}
                name='ownNotebook'
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId='notebookModel'>
              <Form.Label>Modelo de notebook</Form.Label>
              <Form.Control
                type='text'
                placeholder='Modelo de notebook'
                value={this.state.notebookModel}
                disabled={!this.state.ownNotebook}
                name='notebookModel'
                onChange={this.handleChange}
              />
            </Form.Group>
          </Row>

          <hr className='my-4' />
          <Row className='mb-3 justify-content-between'>
            <Col md='3'>
              <Button
                variant='info'
                type='submit'
                className={this.props.match.params.id ? 'w-100' : 'd-none'}
                onClick={() => {
                  this.setState({ backToStudent: true });
                }}
              >
                <i class=' mr-3 fas fa-arrow-left '></i> Guardar y volver al
                alumno
              </Button>
            </Col>
            <Col md='3'>
              <Button
                disabled={this.state.ButtonLoading}
                variant='info'
                type='submit'
                className={this.props.match.params.id ? 'd-none' : 'w-100'}
                onClick={() => {
                  this.setState({ redirectToStudent: true });
                }}
              >
                <i className='mr-3 fas fa-arrow-left'></i>Guardar e ir al alumno
              </Button>
            </Col>
            <Col md='3'>
              <Button
                disabled={this.state.ButtonLoading}
                variant='info'
                type='submit'
                className={
                  this.props.match.params.id ? 'd-none' : 'w-100 d-block'
                }
                onClick={() => {
                  this.setState({ hasToReload: true });
                }}
              >
                <i className='mr-3 fas fa-undo'></i>Guardar y cargar otro
              </Button>
            </Col>
            <Col md='3'>
              <Button
                variant='danger'
                onClick={() => {
                  this.props.history.push(this.state.cancelUrl);
                }}
                className={'w-100 d-block'}
              >
                <FontAwesomeIcon icon={faTimesCircle} /> Cancelar
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Actualización de email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Estas por actualizar el email del estudiante, se actualizarán los
            datos pagos automaticamente para mantener el historial. ¿Deseas
            continuar?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseModal}>
              Cancelar
            </Button>
            <Button variant='primary' onClick={this.onSubmit}>
              Actualizar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(StudentCreate);
