import React, { useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import './createCoupon.css';
import { notify } from 'react-notify-toast';
import clientAxios from '../../clients/clientAxios';

const CreateCoupon = ({ getCoupons }) => {
  const [formData, setFormData] = useState({
    beneficiaryId: '',
    beneficiaryEmail: '',
    beneficiaryName: '',
    amountToDiscount: '',
    comments: 'Generado desde RollingCode Student Manager',
  });
  const [searchValue, setSearchValue] = useState('');
  const [foundStudents, setFoundStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [searchBy, setSearchBy] = useState('email');
  const [formErrors, setFormErrors] = useState({});
  const ENTER_KEY = 'Enter';

  const getStudentListFromDatabase = async () => {
    setLoading(true);
    const params = { [searchBy]: searchValue }
    try {
      const response = await clientAxios.get(`/students`, {
        params: params,
      });
      setFoundStudents(response?.data?.data);
    } catch (error) {
      console.log('error', error);
      notify.show(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setFormErrors((prevErrors) => {
      let newErrors = { ...prevErrors };
      delete newErrors.beneficiaryId;
      return newErrors;
    });
  };

  const handleSearchSubmit = () => {
    getStudentListFromDatabase();
  };

  const handleStudentSelection = (student) => {
    setFormData((prevState) => ({
      ...prevState,
      beneficiaryId: student._id,
      beneficiaryEmail: student.email,
      beneficiaryName: student.name + ' ' + student.surname,
    }));
    setSearchValue('');
    setFoundStudents([]);
    setFormErrors((prevErrors) => {
      let newErrors = { ...prevErrors };
      delete newErrors.beneficiaryId;
      return newErrors;
    });
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event?.target?.name]: event?.target?.value },
    );
  };

  const validateForm = () => {
    let errors = {};
    if (formData.amountToDiscount === null || formData.amountToDiscount < 0 || formData.amountToDiscount > 100) {
      errors.amountToDiscount = 'El descuento debe ser un número entre 0 y 100';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoadingCreate(true);
    const user = JSON.parse(localStorage.getItem("userdata") || "{}");
    try {
      await clientAxios.post('/couponPromos/', { ...formData, user });
      notify.show('Cupón creado correctamente', 'success');
      setFormData({
        beneficiaryId: '',
        beneficiaryEmail: '',
        beneficiaryName: '',
        amountToDiscount: '',
        comments: 'Generado desde RollingCode Student Manager',
      });
      setSearchBy('email');
      getCoupons()
    } catch (error) {
      notify.show(error.response.data.message, 'error');
    } finally {
      setLoadingCreate(false);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY) {
      handleSearchSubmit();
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className='searchContainer'>
          <Col xs={10}>
            <Form.Group controlId='name' className='mt-3'>
              <Form.Label>Buscar Alumno</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder='Buscar Alumno...'
                  name='beneficiaryId'
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyPress}
                  value={searchValue}
                />
                <InputGroup.Append>
                  <Button variant='primary' onClick={handleSearchSubmit} >
                    {loading ? (
                      <>
                        <Spinner animation='border' role='status' size='sm' />
                        Buscando...
                      </>
                    ) : (
                      'Buscar'
                    )}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              {formErrors.beneficiaryId && <p className="error">{formErrors.beneficiaryId}</p>}
              {foundStudents.length > 0 &&
                foundStudents.map((student) => (
                  <Dropdown className='studentsDropdown' key={student.email}>
                    <Dropdown.Item
                      onClick={() => handleStudentSelection(student)}
                      className='studentsDropdown'
                    >
                      {student.email}{' - '}{student.name}{' '}{student.surname}{student.dni && ` - DNI: ${student.dni}`}
                    </Dropdown.Item>
                  </Dropdown>
                ))}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              controlId='exampleForm.ControlSelect1'
              className='serchByCointer mt-3'
            >
              <Form.Label>Buscar por:</Form.Label>
              <Form.Control as='select' defaultValue='email' onChange={handleSearchByChange}>
                <option value={'email'}>Email</option>
                <option value={'dni'}>DNI</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {
            formData?.beneficiaryName && (
              <Col>
                <Form.Group controlId='name' className='mt-3'>
                  <Form.Label>Alumno Seleccionado</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Beneficiario'
                    value={formData?.beneficiaryName}
                    disabled
                  />
                </Form.Group>
              </Col>
            )
          }
        </Row>
        <Form.Group controlId='name' className='mt-3'>
          <Form.Label>Descuento %</Form.Label>
          <Form.Control
            type='number'
            placeholder='Ingrese el monto'
            onChange={handleChange}
            name='amountToDiscount'
            value={formData?.amountToDiscount}
            min={0}
            max={100}
            required
          />
          {formErrors.amountToDiscount && <p className="error">{formErrors.amountToDiscount}</p>}
        </Form.Group>
        <Form.Group controlId='name' className='mt-3'>
          <Form.Label>Reseña (opcional)</Form.Label>
          <Form.Control
            as='textarea'
            rows={5}
            defaultValue={formData?.comments}
            onChange={handleChange}
            name='comments'
          />
        </Form.Group>
        <Row className='d-flex justify-content-center mt-4'>
          <Col xs={8}>
            <Button className='w-100' type='submit'>
              {
                loadingCreate ? (
                  <Spinner animation='border' role='status' size='sm' />
                )
                  :
                  ('Crear')
              }
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateCoupon;
