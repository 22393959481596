import React, { useEffect, useState } from 'react';
import clientAxios from '../../clients/clientAxios';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import LoadingScreen from '../../Layout/LoadingScreen';
import PaymentPlanEditPriceSchemaModal from './PaymentPlanEditPriceSchemaModal';
import moment from 'moment';
import PaymentPlanEditGeneral from './PaymentPlanEditGeneral';
import './paymentsPlan.css';
import { handleKeyDown } from '../../helpers/handleKeyDown';
import {
  handleInputValue,
  handleMaxInputValue,
} from '../../helpers/handleInputValue';

const PaymentsPlan = () => {
  const [planesData, setPlanesData] = useState([]);
  const [modalStatesOptionSee, setModalStatesOptionSee] = useState([]);
  const [modalStatesOptionEdit, setModalStatesOptionEdit] = useState([]);
  const [modalStatesOptionDelete, setModalStatesOptionDelete] = useState([]);
  const [show, setShow] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedState, setCheckedState] = useState({});
  const [selectedOption, setSelectedOption] = useState('regular');
  const [planUpdate, setPlanUpdate] = useState({
    name: '',
    description: '',
    totalAmount: 0,
    priceSchema: [],
    specialPlan: false,
    percentageOfDiscount: 0,
  });
  const FORMAT_DATE = 'DD/MM/YYYY';

  const { name, description, totalAmount, priceSchema } = planUpdate;

  const handleChangePlan = (event, fieldName, index) => {
    const newValue = event.target.value;
    setPlanUpdate((prevState) => {
      if (index >= 0) {
        const updatedPriceSchema = prevState.priceSchema.slice();
        updatedPriceSchema[index] = newValue;
        const totalAmount = updatedPriceSchema.reduce(
          (accumulator, currentValue) => accumulator + +currentValue,
          0
        );
        return {
          ...prevState,
          priceSchema: updatedPriceSchema,
          totalAmount,
        };
      } else {
        return {
          ...prevState,
          [fieldName]: newValue,
        };
      }
    });
  };

  const handleShowAndClose = (index, option) => {
    switch (option) {
      case 'see':
        const updatedModalStatesSee = [...modalStatesOptionSee];
        updatedModalStatesSee[index] = !updatedModalStatesSee[index];
        setModalStatesOptionSee(updatedModalStatesSee);
        break;
      case 'edit':
        const updatedModalStatesEdit = [...modalStatesOptionEdit];
        updatedModalStatesEdit[index] = !updatedModalStatesEdit[index];
        setModalStatesOptionEdit(updatedModalStatesEdit);
        break;
      case 'delete':
        const updatedModalStatesDelete = [...modalStatesOptionDelete];
        updatedModalStatesDelete[index] = !updatedModalStatesDelete[index];
        setModalStatesOptionDelete(updatedModalStatesDelete);
        break;
      default:
        setShow(!show);
        break;
    }
  };

  const getPlanesPayments = async () => {
    try {
      const planes = await clientAxios.get('/planes');
      setPlanesData(planes?.data);
      setIsLoading(false);
    } catch {
      notify.show('No se pudo traer los planes', 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (planesData?.length > 0) {
      const initialCheckedState = planesData.reduce((acc, plan) => {
        acc[plan._id] = plan.specialPlan;
        return acc;
      }, {});
      setCheckedState(initialCheckedState);
    }
  }, [planesData]);

  const normalizeNewPlan = (data) => {
    const amontQuotaFormated = data.priceSchema.map(Number);
    const totalPriceFormated = Number(data.totalAmount);
    const percentageOfDiscountFormated = Number(data.percentageOfDiscount);
    return {
      ...planUpdate,
      priceSchema: amontQuotaFormated,
      totalAmount: totalPriceFormated,
      percentageOfDiscount: percentageOfDiscountFormated,
    };
  };

  const createNewPlan = async () => {
    try {
      if (name && description && totalAmount && priceSchema.length !== 0) {
        const dataNormalized = normalizeNewPlan(planUpdate);
        const resp = await clientAxios.post('/planes', dataNormalized);
        if (resp.status === 201) {
          setShow(false);
          notify.show('Plan creado correctamente', 'success');
        }
        setPlanUpdate({
          name: '',
          description: '',
          totalAmount: 0,
          priceSchema: [],
          percentageOfDiscount: 0,
        });
        setFetchTrigger((prevState) => !prevState);
      } else {
        notify.show('Campo/s Vacio/s', 'error');
      }
    } catch {
      setShow(false);
      notify.show('No se pudo crear el plan', 'error');
    }
  };

  const handleDeletePlan = async (id, index) => {
    try {
      const resp = await clientAxios.delete(`/planes/${id}`);
      if (resp.status === 200) {
        handleShowAndClose(index, 'delete');
        notify.show('Plan eliminado correctamente', 'success');
        setFetchTrigger(true);
      }
    } catch {
      notify.show('No se pudo eliminar el plan', 'error');
    }
  };

  const handleSpecialPlan = async (id, e) => {
    const isChecked = e.target.checked;

    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));

    setPlanUpdate((prevState) => ({
      ...prevState,
      specialPlan: isChecked,
    }));
    try {
      await clientAxios.patch(`/planes/${id}`, { specialPlan: isChecked });
      setFetchTrigger((prevState) => !prevState);
      notify.show('Plan modificado correctamente', 'success');
    } catch {
      notify.show('No se pudo modificar el plan', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const addInput = () => {
    setPlanUpdate((prevState) => ({
      ...prevState,
      priceSchema: [...priceSchema, ''],
    }));
  };

  const deleteInput = (index) => {
    const nuevosInputs = [...priceSchema];
    nuevosInputs.splice(index, 1);
    setPlanUpdate((prevState) => ({
      ...prevState,
      priceSchema: nuevosInputs,
    }));
  };

  const handleFilterPlansChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    getPlanesPayments();
  }, []);

  useEffect(() => {
    if (fetchTrigger) {
      getPlanesPayments();
      setFetchTrigger(false);
    }
  }, [fetchTrigger]);

  const clonePlan = async (plan) => {
    try {
      const actualDate = moment().format('YYYY-MM-DD HH:mm:ss');
      const clonePlan = {
        ...plan,
        name: `${plan.name} Copia`,
        createdAt: actualDate,
        updatedAt: actualDate,
      };
      await clientAxios.post('/planes', clonePlan);
      notify.show('Plan clonado correctamente', 'success');
      setFetchTrigger(true);
    } catch (error) {
      notify('Ha ocurrido un error al clonar este plan.', 'error');
    }
  };

  const filteredPlanesData = planesData?.filter((plan) =>
    selectedOption === 'regular' ? !plan.specialPlan : plan.specialPlan
  );

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div>
            <Form variant='dark'>
              <Form.Group
                controlId='selectForm.ControlSelect1'
                className='ml-5 filterPlan'
              >
                <Form.Control
                  as='select'
                  className='bg-dark filterPlanControl'
                  value={selectedOption}
                  onChange={handleFilterPlansChange}
                >
                  <option value='regular' className='filterPlanOption bg-light'>
                    Planes regulares
                  </option>
                  <option
                    value='especial'
                    className='filterPlanOption bg-light'
                  >
                    Planes especiales
                  </option>
                </Form.Control>
              </Form.Group>
            </Form>
            <Modal show={show} onHide={handleShowAndClose}>
              <Modal.Header closeButton>
                <Modal.Title>Nuevo Plan</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      name='name'
                      onChange={(ev) => handleChangePlan(ev, 'name')}
                      type='text'
                      placeholder='Nombre del Plan'
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control
                      type='text'
                      name='description'
                      onChange={(ev) => handleChangePlan(ev, 'description')}
                      placeholder='Breve descripcion'
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Cuota</Form.Label>
                    <div>
                      <ol>
                        {priceSchema.map((valor, index) => (
                          <div
                            key={index}
                            className='w-75'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <li>
                              <Form.Control
                                type='number'
                                name='priceSchema'
                                min={0}
                                onChange={(ev) => {
                                  const newValue = Number(ev.target.value);
                                  if (newValue < 0) {
                                    handleChangePlan(
                                      { target: { value: 0 } },
                                      -'priceSchema',
                                      index
                                    );
                                  } else {
                                    handleChangePlan(ev, 'priceSchema', index);
                                  }
                                }}
                                onKeyDown={handleKeyDown}
                                onInput={handleInputValue}
                                placeholder='Cuota'
                                style={{ margin: '10px 0px', width: '100%' }}
                              />
                            </li>
                            <div>
                              {index > 0 && (
                                <Button
                                  variant='danger'
                                  type='button'
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => deleteInput(index)}
                                >
                                  -
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                      </ol>
                      <Button
                        variant='success'
                        type='button'
                        onClick={addInput}
                        style={{ marginTop: '10px' }}
                      >
                        +
                      </Button>
                    </div>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Precio Total</Form.Label>
                    <Form.Control
                      type='number'
                      name='totalAmount'
                      value={planUpdate.totalAmount}
                      onChange={(ev) => handleChangePlan(ev, 'totalAmount')}
                      placeholder='Precio del Plan'
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Descuento %</Form.Label>
                    <Form.Control
                      type='number'
                      name='percentageOfDiscount'
                      onInput={handleMaxInputValue}
                      onKeyDown={handleKeyDown}
                      onChange={(ev) =>
                        handleChangePlan(ev, 'percentageOfDiscount')
                      }
                      placeholder='Ej: 10%'
                    />
                  </Form.Group>
                  <Button variant='dark' type='button' onClick={createNewPlan}>
                    Crear Plan
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
          <div className='ml-5 mr-5'>
            <div className='d-flex flex-column justify-content-center mt-2 table-responsive'>
              <div className='d-flex justify-content-end'>
                <Button
                  variant='primary'
                  onClick={handleShowAndClose}
                  className='ml-auto'
                >
                  + Agregar Plan
                </Button>
              </div>
              <Table
                striped
                bordered
                hover
                variant='dark'
                className='w-100 mt-2'
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Total</th>
                    <th>Creado el</th>
                    <th>Cuotas</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPlanesData &&
                    filteredPlanesData.map((plan, index) => (
                      <tr key={index}>
                        <td>{plan.name}</td>
                        <td>{plan.description}</td>
                        <td>${plan.totalAmount}</td>
                        <td>{moment(plan.createdAt).format(FORMAT_DATE)}</td>
                        <td className='text-center'>
                          <PaymentPlanEditPriceSchemaModal
                            key={index}
                            planData={plan}
                            setFetchTrigger={setFetchTrigger}
                          />
                        </td>
                        <td className='d-flex'>
                          <div className='mx-3'>
                            <PaymentPlanEditGeneral
                              key={index}
                              planData={plan}
                              setFetchTrigger={setFetchTrigger}
                            />
                          </div>
                          <div>
                            <Button
                              variant='danger'
                              onClick={() =>
                                handleShowAndClose(index, 'delete')
                              }
                              key={index}
                            >
                              Eliminar
                            </Button>

                            <Modal
                              show={modalStatesOptionDelete[index]}
                              onHide={() => handleShowAndClose(index, 'delete')}
                              key={index}
                            >
                              <Modal.Body>
                                <div>
                                  <h3>
                                    ¿Estas seguro que quieres eliminar este
                                    plan: {plan.name}?
                                  </h3>
                                  <div>
                                    <Button
                                      variant='danger'
                                      className='mx-3'
                                      onClick={() =>
                                        handleDeletePlan(plan._id, index)
                                      }
                                    >
                                      SI, Estoy seguro
                                    </Button>
                                    <Button
                                      variant='secondary'
                                      onClick={() =>
                                        handleShowAndClose(index, 'delete')
                                      }
                                    >
                                      NO
                                    </Button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div className='mx-3'>
                            <Button
                              variant='warning'
                              onClick={() => clonePlan(plan)}
                              key={index}
                            >
                              Clonar
                            </Button>
                          </div>
                          <div className='d-flex align-items-center'>
                            <input
                              type='checkbox'
                              onChange={(e) => handleSpecialPlan(plan._id, e)}
                              checked={checkedState[plan._id] || false}
                            />
                            <p className='mb-0 mx-2'>
                              Marcar como caso especial
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentsPlan;
