import React, { Component, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  ListGroup,
} from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import { ClassroomList } from '../../Context/ClassroomListContext';
import { availableCourses } from '../../Context/Courses';

import { notify } from 'react-notify-toast';
import EditBill from './EditBill';

import LoadingScreen from '../../Layout/LoadingScreen';
import AskToPrint from './AskToPrint';

moment.locale('es');
class CreateBill extends Component {
  static contextType = ClassroomList;

  constructor(props) {
    super(props);
    this.state = {
      editBillModal: false,
      billId: '',
      prepay: false,
      fullPaid: false,
      amount: 0,

      currentDate: new Date(),
      date: '',
      months: [],

      student: '',
      studentId: '',
      nameDisplay: '',
      nameDisplayEdit: '',
      email: '',
      courseFromClassroom: '',
      classroom: '',
      classroomCopy: '',
      isActive: [],
      installmentsCopy: '',
      ogAmount: 0,
      customAmount: '',
      percent: '',
      prePercentAmount: 0,
      payment: 'Efectivo',
      selectedOption: 0,
      monthlyInstallment: 0,
      installmentQuantity: '',
      totalPaid: false,
      additionalNotes: '',
      multiplePayments: false,
      scholarship: [],
      multiPay: [
        { cuota: 1, checked: false },
        { cuota: 2, checked: false },
        { cuota: 3, checked: false },
        { cuota: 4, checked: false },
        { cuota: 5, checked: false },
        { cuota: 6, checked: false },
        { cuota: 7, checked: false },
        { cuota: 8, checked: false },
        { cuota: 9, checked: false },
        { cuota: 10, checked: false },
        { cuota: 11, checked: false },
        { cuota: 12, checked: false },
      ],
      createdBy: '',
      lastUpdateBy: '',
      backlog: [],
      oldBacklog: [],
      combined: [],
      name: '',
      surname: '',
      course: '',
      selectedCourse: '',
      selectedClassroom: '',
      monthException: [],
      comisionException: [],
      isLoading: true,
      disabled: false,
      buttonText: 'Guardar datos',
      hasToUpdate: false,
      price: '',
      modals: {
        askToPrint: { show: false, id: '' },
      },
      modalShow: false,
      modalTitle: '',
      modalBody: '',
      modalVariant: '',
      modalAction: console.log(''),
      currency: '',
    };
  }

  componentDidMount() {
    let { stuid } = this.props.match.params;

    if (stuid === 'new') {
      this.setupNewBill();
    } else {
      axios
        .get(`/api/students/${stuid}`, {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        })
        .then(({ data }) => {
          this.assignStudentDataToState(data.response);
        })
        .catch((err) => {
          notify.show(`Ocurrio un error: ${err.message}`, 'error');
          this.props.history.push('/students/viewAll');
        });
    }
  }

  componentDidUpdate() {
    let { stuid } = this.props.match.params;

    if (stuid === 'new') {
      if (this.context !== this.state.classroom) {
        this.setupNewBill();
      }
    }
  }

  /**
   * Cuando la pantalla se inicializa el conteo de
   * cuotas no se realiza automáticamente, entonces
   * esta función corrige eso.
   */
  showInstallmentQuantityFromFirstSelectedCourse = () => {
    let a = this.state.selectedOption,
      m =
        typeof this.state.course === 'object'
          ? this.state.installmentQuantity[a]
          : this.state.installmentQuantity,
      array = [],
      i;

    for (i = 0; i < m; i++) {
      array.push(i + 1);

      if (i === m - 1) {
        let array2 = array.filter(this.monthsPayed);

        this.setState({
          months: array2,
          monthlyInstallment: 0,
        });
      }
    }
  };

  /**
   * Asigna todos los datos provenientes del
   * alumno
   *
   * @param {object} data Datos del estudiante
   */
  assignStudentDataToState = (data) => {
    let classroom =
      typeof data.classroom === 'object'
        ? data.isActive[0] === 'down' ||
          ('commissionChanged' && data.classroom[1])
          ? data.classroom[1]
          : data.classroom[0]
        : data.classroom;
    let selectedPrice =
      typeof data.course === 'object'
        ? data.isActive[0] === 'down' ||
          data.isActive[0] === 'commissionChanged'
          ? data.isActive[1] === 'down' ||
            data.isActive[1] === 'commissionChanged'
            ? data.isActive[2] === 'down' ||
              data.isActive[2] === 'commissionChanged'
              ? 0
              : data.price[2]
              ? data.price[2] === null
                ? 0
                : parseFloat(data.price[2])
              : 0
            : data.price[1]
            ? data.price[1] === null
              ? 0
              : parseFloat(data.price[1])
            : 0
          : data.price[0]
          ? data.price[0] === null
            ? 0
            : parseFloat(data.price[0])
          : 0
        : data.price;
    let selectedCourse =
      typeof data.course === 'object'
        ? data.isActive[0] === 'down' || ('commissionChanged' && data.course[1])
          ? data.course[1]
          : data.course[0]
        : data.course;
    let selectedCurrency =
      typeof data.currency === 'object'
        ? data.isActive[0] === 'down' ||
          data.isActive[0] === 'commissionChanged'
          ? data.isActive[1] === 'down' ||
            data.isActive[1] === 'commissionChanged'
            ? data.isActive[2] === 'down' ||
              data.isActive[2] === 'commissionChanged'
              ? 'ARS'
              : data.currency[2]
              ? data.currency[2] === ''
                ? 'ARS'
                : data.currency[2]
              : 'ARS'
            : data.currency[1]
            ? data.currency[1] === ''
              ? 'ARS'
              : data.currency[1]
            : 'ARS'
          : data.currency[0]
          ? data.currency[0] === ''
            ? 'ARS'
            : data.currency[0]
          : 'ARS'
        : data.currency;

    this.setState(
      (prevState) => ({
        selectedOption:
          data.isActive[0] === 'down' || ('commissionChanged' && data.course[1])
            ? 1
            : 0,
        isActive: data.isActive,
        prepay: data.isActive === 'prepay',
        name: data.name,
        surname: data.surname,
        studentId: data._id,
        nameDisplay: data.name + ' ' + data.surname,
        date: moment(prevState.currentDate).format('YYYY-MM-DD'),
        classroom: data.classroom,
        classroomCopy: classroom,
        price: data.price,
        amount: selectedPrice,
        ogAmount: selectedPrice,
        customAmount: selectedPrice,
        prePercentAmount: selectedPrice,
        monthlyInstallment: data.monthlyInstallments,
        installmentQuantity: data.monthlyInstallments,
        courseQuantity: data.courseQuantity,
        course: this.state.selectedCourse || data.course,
        selectedCourse,
        selector: 0,
        email: data.email,
        scholarship: data.scholarship,
        currency: selectedCurrency,
        currencyArray: data.currency,
      }),
      () => {
        this.showInstallmentQuantityFromFirstSelectedCourse();
        this.getPastBills();
      }
    );
  };

  /**
   * Si se tiene que crear una boleta de 0
   * esta funcion prepara la pantalla para tener todos
   * los datos necesarios sin necesidad
   * de un alumno
   */
  setupNewBill = () => {
    let array = [];
    let classroom = this.context.crList;

    for (let i = 0; i < 12; i++) {
      array.push(i + 1);
    }
    this.setState({
      isLoading: false,
      months: array,
      editing: false,
    });

    let installments = [12, 12, 9, 9, 9, 3, 3, 6, 9],
      todayDate = moment(new Date()).format('YYYY-MM-DD');

    this.setState({
      classroom,
      course: availableCourses,
      selectedCourse: availableCourses[0],
      installmentQuantity: installments,
      date: todayDate,
    });
  };

  /**
   * Trae las boletas anteriores para ser mostradas
   * al final de la pantalla
   */
  getPastBills = () => {
    axios
      .get(`/api/bills/get/studentid/${this.state.studentId}`, {
        headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
      })
      .then((i) => {
        this.setState({ combined: i.data });

        const { priceSchema } = this.context.getPlanContext();
        let cuotasFinales = this.checkForInstallments();

        const selectedInstallmentIndex = cuotasFinales[0]
          ? parseInt(cuotasFinales[0]) - 1
          : 0;

        const amount =
          priceSchema && priceSchema[selectedInstallmentIndex]
            ? priceSchema[selectedInstallmentIndex]
            : 0;

        this.setState({
          amount: amount,
          monthlyInstallment: cuotasFinales[0],
          isLoading: false,
        });
      })
      .catch((err) => {
        notify.show(`Ocurrio un error: ${err.message}`, 'error');
        this.props.history.push('/students/viewAll');
      });
  };

  /**
   * Ojalá puedas entender que hace esto, pero
   * no se te ocurra borrarlo porque revienta todo.
   *
   * Si le encontrás la vuelta por favor comentalo.
   */
  monthsPayed = (months, value) => {
    return months > Math.max(value);
  };

  returnHome = () => {
    this.props.history.push('/students/viewAll');
  };

  /**
   * Muestra el modal que pregunta al usuario si desea
   * imprimir la boleta, además alimenta el id de dicha boleta
   * para que pueda ser cargado por la siguiente pantalla
   *
   * @param {string} id Id de la boleta a abonar
   */
  askToPrint = (id) => {
    this.setState((i) => ({
      modals: {
        ...i.modals,
        askToPrint: { show: !i.modals.askToPrint.show, id },
      },
    }));
  };

  /**
   * Redirecciona a la pantalla de impresión
   */
  redirectToBillPrint = () => {
    this.props.history.push('/bills/print/' + this.state.modals.askToPrint.id);
  };

  /**
   * Maneja el cambio entre los inputs y el state
   *
   * @param {*} obj Input
   */
  handleChange = (obj) => {
    let { name, value, type, checked, selectedIndex } = obj.target;

    if (name === 'classroomCopy') {
      if (this.context.crList && this.context.crList.length !== 0) {
        let course = this.context.crList?.filter((item) => {
          return value ? item.classroom === value : null;
        });

        this.setState({ [name]: value, selectedClassroom: value }, () => {
          if (course) {
            this.handleMultipleCourseBill(course[0].course, selectedIndex);
          }
        });
      }
    } else {
      type === 'checkbox'
        ? this.setState({ [name]: checked })
        : this.setState({ [name]: value });
    }

    if (name === 'installmentsCopy') {
      const selectedInstallmentIndex = parseInt(value) - 1;

      const { priceSchema } = this.context.getPlanContext();

      if (
        selectedInstallmentIndex >= 0 &&
        selectedInstallmentIndex < priceSchema.length
      ) {
        this.setState({ amount: priceSchema[selectedInstallmentIndex] || 0 });
      } else {
        this.setState({ amount: 0 });
      }
    }

    if (name === 'classroomCopy') {
      if (this.state.scholarship[selectedIndex - 1]) {
        this.setState({ disabled: true });
      } else {
        this.setState({ disabled: false });
      }
    }
  };

  /**
   * Envía datos al server
   *
   * @param {*} obj Botón de submit
   */
  handleSubmit = (obj) => {
    obj.preventDefault();

    const {
      studentId,
      nameDisplay,
      date,
      ogAmount,
      amount,
      monthlyInstallment,
      payment,
      partialPayment,
      additionalNotes,
      percent,
      email,
      prepay,
      currency,
    } = this.state;

    // Configuración para la edición
    const putConfig = {
      studentId,
      nameDisplay,
      date,
      classroom: this.state.classroomCopy,
      amount,
      payment,
      additionalNotes,
      percent,
      prepay,
      monthlyInstallment:
        monthlyInstallment && !this.state.multiplePayments
          ? monthlyInstallment
          : this.state.multiPay
              .filter((i) => i.checked === true)
              .map((i) => i.cuota)
              .toString(),
      partial: partialPayment,
      course: this.state.selectedCourse,
      currency,
    };

    // Configuración para la creación
    let postConfig = {
      studentId:
        this.props.match.params.stuid === 'new' ? null : this.state.studentId,
      nameDisplay,
      date,
      email,
      amount,
      ogAmount,
      payment,
      additionalNotes,
      percent,
      prepay,
      monthlyInstallment:
        monthlyInstallment && !this.state.multiplePayments
          ? monthlyInstallment
          : this.state.multiPay
              .filter((i) => i.checked === true)
              .map((i) => i.cuota)
              .toString(),
      partial: partialPayment,
      installmentQuantity: this.state.months.length,
      course: this.state.selectedCourse,
      classroom: this.state.classroomCopy,
      currency,
    };

    if (amount < 1) {
      return notify.show('Debe especificar un precio', 'warning');
    }

    this.setState({ disabled: true, buttonText: 'Guardando...' });

    if (this.state.hasToUpdate) {
      axios
        .put(`/api/bills/put/${this.props.match.params.bilid}`, putConfig, {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        })
        .then((res) => {
          notify.show(res.data.message, 'success');
          this.askToPrint(res.data.id);
        })
        .catch((err) => {
          this.setState({ disabled: false, buttonText: 'Guardar datos' });
          notify.show(`Ocurrio un error: ${err.message}`, 'error');
        });
    } else {
      if (this.state.totalPaid) {
        postConfig = { ...postConfig, fullPaid: true };
      }

      axios
        .post('/api/bills/post', postConfig, {
          headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
        })
        .then(async (res) => {
          if (res.data.success) {
            if (res.data.success === false) {
              notify.show(`Ocurrio un error: ${res.data.message}`, 'error');
            }
          } else {
            notify.show(res.data.message, 'success');
            this.askToPrint(res.data.id);
          }
        })
        .catch((err) => {
          this.setState({ disabled: false, buttonText: 'Guardar datos' });
          notify.show(`Ocurrio un error: ${err.message}`, 'error');
          this.returnHome();
        });
    }
  };

  /**
   * Activa o desactiva el modo pagos múltiples
   */
  toggleMultiplePayments = () => {
    this.setState((prevState) => ({
      multiplePayments: !prevState.multiplePayments,
      additionalNotes: '',
    }));
  };

  /**
   * Cuando se aplica el porcentaje, se realizan las
   * operaciones matemáticas necesarias para que el precio coincida.
   *
   * Si el porcentaje cambia y luego se reasigna a 0, el
   * precio final vuelve al original establecido desde un principio
   * sea el que viene del alumno o el precio asignado manualmente
   *
   * (Véase: handlePrice)
   * @param {*} obj Input del porcentaje
   */
  percent = (obj) => {
    let { prePercentAmount } = this.state,
      { value } = obj.target;

    if (value === 0) {
      this.setState((i) => ({
        amount: i.prePercentAmount,
        percent: 0,
      }));
    } else {
      let percent = parseFloat((prePercentAmount * value) / 100),
        result = parseFloat(percent) + parseFloat(prePercentAmount),
        roundedResult = Math.ceil(result / 5) * 5;

      this.setState({
        amount: roundedResult,
        percent: value,
      });
    }
  };

  /**
   * Al modificar manualmente el precio, se modifican los
   * otros valores (Pre percent amount y custom amount)
   * para que al ser modificados por multiPayHandle() o
   * percent() tome este nuevo valor
   * @param {*} obj Input del precio
   */
  handlePrice = (obj) => {
    let { value } = obj.target;

    this.setState({
      amount: parseFloat(value),
      prePercentAmount: parseFloat(value),
      customAmount: parseFloat(value),
    });
  };

  /**
   * Manejo de las múltiples cuotas, toma el id del checkbox y
   * realiza el manejo necesario para modificar el precio y
   * mostrar en "Observaciones" la cuota seleccionada
   *
   * @param {number} param0 Checkbox input
   */
  multiPayHandle = async (props) => {
    let array = this.state.multiPay;

    let { id } = props.target;

    array[id - 1].checked = !array[id - 1].checked;

    let bill = array.filter((i) => i.checked === true),
      multipliedPrice =
        bill.length >= 1
          ? this.state.customAmount * bill.length
          : this.state.customAmount;

    await this.setState({
      percent: 0,
      multiPay: array,
      additionalNotes: `Se abonan las boletas: ${bill.map((i) => i.cuota)}`,
      amount: multipliedPrice,
      prePercentAmount: multipliedPrice,
    });
  };

  /**
   * Se encarga de cambiar todos los valores correspondientes
   * segun el curso seleccionado
   *
   * @param {*} obj Input de curso
   * @param {number} index Index del curso seleccionado
   */
  handleMultipleCourseBill = (obj, index) => {
    let value;

    if (typeof obj === 'object') {
      value = obj.target.value;
      index = obj.target.selectedIndex - 1;
    } else {
      value = obj;
      --index;
    }

    const isNew = this.props.match.params.stuid === 'new';
    this.setState(
      (i) => ({
        selectedOption: index,
        selectedCourse: value,
        classroomCopy: isNew ? i.classroomCopy : i.classroom[index],
        installmentsCopy: i.monthlyInstallment,
        ogAmount: parseFloat(i.price[index]),
        amount: parseFloat(i.price[index]),
        customAmount: parseFloat(i.price[index]),
        prePercentAmount: parseFloat(i.price[index]),
        currency: i.currencyArray[index],
      }),
      () => {
        let m = this.state.installmentQuantity[index],
          array = [],
          i;

        for (i = 0; i < m; i++) {
          array.push(i + 1);

          if (i === m - 1) {
            let array2 = array.filter(this.monthsPayed);
            this.setState({ months: array2, monthlyInstallment: 0 });
          }
        }
      }
    );
  };

  modalHandleClose = () => {
    this.setState((i) => ({ modalShow: !i.modalShow }));
  };

  /**
   * Chequeo de cuotas restantes para abonar
   */
  checkForInstallments = () => {
    let classroomCopy =
        typeof this.state.classroomCopy === 'object'
          ? this.state.classroomCopy[0]
          : this.state.classroomCopy,
      billsFromSingleClassroom = [];

    billsFromSingleClassroom = this.state.combined.filter((i) => {
      return i.classroom ? i.classroom === classroomCopy : false;
    });

    // billsFromSingleClassroom.length !== 0 &&
    // classroomCopy === this.state.classroom[0]
    //   ? (bills = this.state.combined)
    //   : (bills = billsFromSingleClassroom);

    let justBills = [];
    billsFromSingleClassroom.map((i) => {
      justBills.push(
        i.monthlyInstallment?.length > 2
          ? i.partial
            ? Math.max(...JSON.parse('[' + i.monthlyInstallment + ']')) - 1
            : Math.max(...JSON.parse('[' + i.monthlyInstallment + ']'))
          : i.partial
          ? parseFloat(i.monthlyInstallment) - 1
          : parseFloat(i.monthlyInstallment)
      );

      return 0;
    });
    let highNumber = Math.max(...justBills);

    return this.state.months.slice(highNumber);
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    } else {
      let cuotasFinales = this.checkForInstallments();

      /**
       * Devuelve los classrooms activos para efectuar un pago
       */

      const classroomOptions =
        typeof this.state.classroom === 'object' ? (
          this.state.classroom.map((classroom, index) => {
            return classroom.classroom ? (
              <option value={classroom.classroom} key={index}>
                {classroom.classroom}
              </option>
            ) : classroom !== '' ? (
              this.state.isActive[index] === 'down' ? (
                <option
                  value={classroom}
                  hidden
                  key={index}
                >{`${classroom}`}</option>
              ) : this.state.isActive[index] === 'commissionChanged' ? (
                <option
                  value={classroom}
                  hidden
                  key={index}
                >{`${classroom}`}</option>
              ) : (
                <option value={classroom} key={index}>{`${classroom}`}</option>
              )
            ) : null;
          })
        ) : (
          <option>{this.state.classroom}</option>
        );

      return (
        <Container>
          <EditBill
            show={this.state.editBillModal}
            onHide={() =>
              this.setState((i) => ({ editBillModal: !i.editBillModal }))
            }
            id={this.state.billId}
            refresh={this.getPastBills}
          />
          <AskToPrint
            returnHome={this.returnHome}
            show={this.state.modals.askToPrint.show}
            onHide={() => this.askToPrint()}
            print={() => this.redirectToBillPrint()}
          />
          <Row className='bg-light rounded shadow p-3'>
            <Row className='my-4 w-100'>
              <Col>
                <p className='lead'>Realizar el nuevo pago</p>
              </Col>
            </Row>
            <Row className='w-100'>
              <Col>
                <Form
                  onSubmit={this.handleSubmit}
                  className='w-100'
                  autoComplete='off'
                >
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group controlId='nameDisplay'>
                          <Form.Label>Estudiante:</Form.Label>
                          <Form.Control
                            type='text'
                            name='nameDisplay'
                            value={
                              this.props.match.params.stuid === 'new'
                                ? this.state.nameDisplay
                                : this.state.editing
                                ? `${this.state.nameDisplayEdit}`
                                : `${this.state.name} ${this.state.surname}`
                            }
                            onChange={this.handleChange}
                            className='w-90'
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Label>Fecha:</Form.Label>
                        <InputGroup>
                          <FormControl
                            type='date'
                            name='date'
                            value={this.state.date}
                            onChange={this.handleChange}
                            className='w-90'
                          />
                        </InputGroup>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>Curso</Form.Label>
                          <Form.Control
                            disabled
                            as='select'
                            value={this.state.selectedCourse}
                            name='selectedCourse'
                            onChange={this.handleMultipleCourseBill}
                            required
                          >
                            {typeof this.state.course === 'object' ? (
                              this.state.course.map((a, i) => (
                                <option data-index={i} value={a} key={i}>
                                  {a}
                                </option>
                              ))
                            ) : (
                              <option>{this.state.course}</option>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col className='mt-4'>
                        <Form.Group
                          className='p-3 border rounded'
                          controlId='partialPayment'
                        >
                          <Form.Check
                            disabled={this.state.fullPaid}
                            inline
                            onChange={this.handleChange}
                            type='checkbox'
                            checked={this.state.totalPaid}
                            name='totalPaid'
                            id='totalPaid'
                            label='Marcar como Pago Total'
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Comisión</Form.Label>
                          <Form.Control
                            as='select'
                            value={this.state.classroomCopy}
                            name='classroomCopy'
                            onChange={this.handleChange}
                            required
                            disabled={classroomOptions.length <= 1}
                          >
                            <option hidden value=''>
                              Seleccione una opcion
                            </option>
                            {classroomOptions}
                          </Form.Control>
                          <Form.Text
                            className={
                              this.state.scholarship[this.state.selectedOption]
                                ? 'text-danger'
                                : 'd-none'
                            }
                          >
                            ADVERTENCIA! - Esta comisión cuenta con una beca
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId='monthlyInstallment'>
                          <Form.Label
                            className={
                              this.state.multiplePayments ? 'text-muted' : null
                            }
                          >
                            Mes a abonar
                          </Form.Label>
                          <Form.Control
                            disabled={
                              cuotasFinales.length === 0 ||
                              this.state.multiplePayments ||
                              this.state.fullPaid
                            }
                            as='select'
                            value={
                              this.state.installmentsCopy ||
                              cuotasFinales[0] ||
                              ''
                            }
                            name='installmentsCopy'
                            onChange={this.handleChange}
                            required
                          >
                            <option disabled defaultValue value=''>
                              Seleccione una opcion
                            </option>
                            {cuotasFinales.map((i) => (
                              <option value={i} key={i}>
                                Cuota {i}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Tipo de moneda</Form.Label>

                          <Form.Control
                            as='select'
                            name='currency'
                            onChange={this.handleChange}
                            value={this.state.currency}
                          >
                            <option value='' hidden>
                              Seleccione una moneda...
                            </option>
                            <option value='ARS'>ARS</option>
                            <option value='USD'>USD</option>
                            <option value='EUR'>EUR</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>Método de pago</Form.Label>
                          <Form.Control
                            disabled={this.state.fullPaid}
                            as='select'
                            value={this.state.payment}
                            name='payment'
                            onChange={this.handleChange}
                            required
                          >
                            <option value=''>Seleccione una opcion</option>
                            <option>Efectivo</option>
                            <option>Banco</option>
                            <option>Tarjetas</option>
                            <option>MercadoPago</option>
                            <option>Paypal</option>
                            <option>Payway</option>
                            <option>Otro</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Label>
                          Monto a pagar{' '}
                          {this.state.ogAmount !== this.state.amount ? (
                            <small
                              className='text-danger'
                              style={{ cursor: 'pointer', fontStyle: 'italic' }}
                              onClick={() => {
                                this.setState((prevState) => ({
                                  additionalNotes: '',
                                  multiPay: [
                                    { cuota: 1, checked: false },
                                    { cuota: 2, checked: false },
                                    { cuota: 3, checked: false },
                                    { cuota: 4, checked: false },
                                    { cuota: 5, checked: false },
                                    { cuota: 6, checked: false },
                                    { cuota: 7, checked: false },
                                    { cuota: 8, checked: false },
                                    { cuota: 9, checked: false },
                                    { cuota: 10, checked: false },
                                    { cuota: 11, checked: false },
                                    { cuota: 12, checked: false },
                                  ],
                                  customAmount: prevState.ogAmount,
                                  percent: 0,
                                  prePercentAmount: prevState.ogAmount,
                                  amount: prevState.ogAmount,
                                }));
                              }}
                            >
                              (Reset)
                            </small>
                          ) : null}
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type='number'
                            disabled={this.state.fullPaid}
                            name='amount'
                            value={this.state.amount}
                            onChange={this.handlePrice}
                            className='w-auto'
                          />
                        </InputGroup>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>Porcentaje</Form.Label>
                          <Form.Control
                            disabled={this.state.fullPaid}
                            as='select'
                            value={this.state.percent}
                            name='percent'
                            onChange={this.percent}
                          >
                            <option value='0'>0</option>
                            <option value='7'>+7%</option>
                            <option value='12'>+12%</option>
                            <option value='21'>+21%</option>
                            <option value='-7'>-7%</option>
                            <option value='-12'>-12%</option>
                            <option value='-21'>-21%</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Observaciones</Form.Label>
                        <InputGroup>
                          <FormControl
                            name='additionalNotes'
                            value={this.state.additionalNotes}
                            onChange={this.handleChange}
                            className='w-90'
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          disabled={this.state.disabled}
                          className='mt-4'
                          variant='info'
                          type='submit'
                        >
                          {' '}
                          <i className='far fa-save mr-3'></i>{' '}
                          {this.state.buttonText}{' '}
                        </Button>
                      </Col>

                      <Col className='text-right'>
                        <Button
                          className='mt-4'
                          variant='info'
                          onClick={this.toggleMultiplePayments}
                        >
                          {' '}
                          <i className='fas fa-edit mr-3'></i>{' '}
                          {this.state.multiplePayments
                            ? 'Ingresar pago único'
                            : 'Ingresar múltiples pagos'}{' '}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Form>
              </Col>
              <Col
                className={
                  this.state.multiplePayments
                    ? 'd-block border-gray rounded p-3'
                    : 'd-none'
                }
                md='2'
              >
                <Form>
                  <Form.Group controlId='formBasicEmail'>
                    {this.state.multiPay.map((d, i) => (
                      <Form.Check
                        key={i}
                        onChange={this.multiPayHandle}
                        type='checkbox'
                        id={d.cuota}
                        checked={d.checked}
                        label={`Cuota ${i + 1}`}
                      />
                    ))}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Row>

          <Row className='my-4 bg-light p-3 rounded shadow overflow-scroll'>
            <Col>
              <Row className='w-100 mb-2'>
                <h1 className='small'>Pagos realizados por el alumno:</h1>{' '}
              </Row>
              <ListGroup.Item>
                <Row>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>N°</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Fecha</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Fecha creación</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Monto</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Método</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Comisión</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center' md='1'>
                    <small className='m-0 py-0 text-dark'>Cuota</small>
                  </Col>
                  <Col className='mx-0 px-0 text-center'>
                    <small className='m-0 py-0 text-dark'>Curso</small>
                  </Col>
                  <Col className='d-block mx-0 px-0 text-right'>
                    <small className='m-0 py-0 text-dark'>Nota adicional</small>
                  </Col>
                </Row>
              </ListGroup.Item>

              <ListGroup className='mb-3'>
                {this.state.combined.map((item, i) => (
                  <ListGroup.Item
                    key={i}
                    variant={item.partial ? 'info' : 'light'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        billId: item._id,
                        editBillModal: !i.editBillModal,
                      });
                    }}
                  >
                    <Row>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>{item.billNumber}</p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>
                          {moment(item.date).utc().format('L')}
                        </p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>
                          {moment(item.createdAt).utc().format('L')}
                        </p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>${item.amount}</p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>{item.payment}</p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>{item.classroom}</p>
                      </Col>
                      <Col className='mx-0 px-0 text-center' md='1'>
                        <p className='m-0 py-0 text-dark'>
                          {item.monthlyInstallment}
                        </p>
                      </Col>
                      <Col className='mx-0 px-0 text-center'>
                        <p className='m-0 py-0 text-dark'>{item.course}</p>
                      </Col>
                      <Col className='d-block mx-0 px-0 text-right'>
                        <p className='m-0 py-0 text-dark'>
                          {item.additionalNotes}
                        </p>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
export default CreateBill;
