export const handleInputValue = (ev) => {
  const newValue = Number(ev.target.value);
  if (newValue < 0) {
    ev.target.value = 0;
  }
};

export const handleMaxInputValue = (ev, max = 100) => {
  const newValue = Number(ev.target.value);
  if (newValue < 0) {
    ev.target.value = 0;
  } else if (newValue > max) {
    ev.target.value = max;
  }
};
